import AuthDrawer from "../../components/AuthDrawer";
import {useHistory, useParams} from "react-router-dom";
import useCallDataApi from "../../hooks/data";
import {useEffect, useRef, useState} from "react";
import Loading from "../../components/Loading";
import {
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack, TextField,
    Typography,
    useTheme
} from "@mui/material";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import thousandSeparator from "../../utils/NumberUtils";
import DinoDataGrid from "../../components/DataGrid";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DinoDialog from "../../components/Dialog";
import {getFormData} from "../../utils/formUtils";
import ModifierData from "./ModifierData";
import {getYears} from "../../utils/miscUtils";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {useSnackbar} from "notistack";

const SavedCommissionDetail = () => {
    const params = useParams()
    const history = useHistory()
    const {getData: getUser, postData: postUser} = useCallDataApi('users')
    const {getData: getGroup} = useCallDataApi('controlling-group')
    const {getData: getCommission} = useCallDataApi('saved-commissions')
    const [user, setUser] = useState({})
    const [data, setData] = useState([])
    const [totalKEFFee, setTotalKEFFee] = useState(0)
    const [comment, setComment] = useState({})
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedGroup, setSelectedGroup] = useState(parseInt(localStorage.getItem('group-select')) || -1)
    const [groups, setGroups] = useState([])
    const [bonusModifiers, setBonusModifiers] = useState([])

    const [loading, setLoading] = useState(true)
    const theme = useTheme()


    document.title = 'Ügyintézői jutalék'

    useEffect(() => {
        const getData = async () => {
            const [c] = await Promise.all([
                getCommission(params?.id)
            ])
            if (c) {
                console.log(c)
                setData(c?.data)
                setComment({comment: c?.comment})
                setBonusModifiers(c?.modifiers)
                setTotalKEFFee(c?.commission_fee)
                setSelectedYear(c?.year)
                setSelectedMonth(c?.month)
                setUser(c?.user_data)
            }
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const income = () => {
        let i = 0
        for (let d of data) {
            if (![5, 8].includes(d.mirr_class)) i += d.commission_gross_value
        }
        return Math.round(i * 10) / 10
    }

    const expense = () => {
        let e = 0
        for (let d of data) {
            if ([5, 8].includes(d.mirr_class)) e += d.commission_gross_value
        }
        return Math.round(e * 10) / 10
    }

    const bonusBase = () => (Math.round((income() - expense()) * 10) / 10)

    const grossBonus = (minusEverything, percent) => {
        return Math.round(minusEverything * (percent / 100))
    }

    const netBonus = (gross, multiplier) => Math.round(gross * multiplier)

    const sumModifiers = () => {
        let sum = 0
        bonusModifiers.forEach(m => sum += m?.amount)
        return sum
    }

    const columns = [
        {headerName: 'Munkaszám', field: 'MUNKASZAM'},
        {headerName: 'Cikk megnevezés', field: 'ckname'},
        {headerName: 'Tétel megnevezés', field: 'mirr_name1'},
        {headerName: 'Főkönyvi szám', field: 'mirr_code'},
        {headerName: 'Ügyfélnév', field: 'UGYFEL_NEV'},
        {headerName: 'Bizonylat azonosító', field: 'BIZ_AZONOS'},
        {headerName: 'Bizonylat iktatószám', field: 'BIZ_IKTATO'},
        {headerName: 'Költséghely név', field: 'KTGHELY'},
        {headerName: 'Tétel megjegyzés', field: 'MEGJEGYZES'},
        {headerName: 'Napló megnevezés', field: 'LOGNAME'},
        {headerName: 'Deviza', field: 'DEVIZA'},
        {headerName: 'Teljesítés dátuma', field: 'TELJDAT', type: 'date'},
        {headerName: 'Kiállítás dátuma', field: 'KIALLDAT', type: 'date'},
        {headerName: 'Áfa kód', field: 'AFAKOD'},
        {headerName: 'Árfolyam', field: 'ARFOLYAM', type: 'number'},
        {headerName: 'T/K', field: 'mirr_type'},
        {headerName: 'Devizás összeg', field: 'DEV_OSSZ', type: 'number'},
        {headerName: 'Tartozik összeg', field: 'T_OSSZ', type: 'number'},
        {headerName: 'Követel összeg', field: 'K_OSSZ', type: 'number'},
        {headerName: 'Szín', field: 'color'},
        {headerName: 'Komment', field: 'comment'},
        {
            headerName: 'Egyenleg',
            field: 'summed_gross_value',
            type: 'number',
            renderCell: params => {
                if (params.row['gross_value'] > 0) return <p
                    style={{color: 'green'}}>{thousandSeparator(params.row['gross_value'])}</p>
                else return <p style={{color: 'red'}}>{thousandSeparator(params.row['gross_value'])}</p>
            }
        },
        {
            headerName: 'Módosított jutalék alap',
            field: 'commission_gross_value',
            type: 'number',
            renderCell: params => {
                if (params.row['commission_gross_value'] > 0) return <p
                    style={{color: 'green'}}>{thousandSeparator(params.row['commission_gross_value'])}</p>
                else return <p style={{color: 'red'}}>{thousandSeparator(params.row['commission_gross_value'])}</p>
            }
        },
    ]
    const summedModifiers = sumModifiers()
    // console.log(`summedModifiers: ${summedModifiers}`)
    const bonusBase1 = bonusBase() + summedModifiers
    // console.log(`bonusBase1: ${bonusBase1}`)
    const minusKef = bonusBase1 - totalKEFFee
    // console.log(`minusKef: ${minusKef}`)
    // const fivePercent = minusKef * 0.05
    // const minus5percent = minusKef * 0.95
    // console.log(`minus5percent: ${minus5percent}`)
    // const gross = grossBonus(minus5percent, user?.bonus_percent)
    const gross = grossBonus(minusKef, user?.bonus_percent)
    // console.log(`gross: ${gross}`)
    const net = netBonus(gross, user?.bonus_multiplier)

    const afterModifiers = net

    return <AuthDrawer label={user?.name}>
        <Stack direction='row' sx={{justifyContent: 'space-between'}} marginBottom={3}>
            <Button variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.goBack()}>Vissza</Button>
        </Stack>
        <Stack spacing={2} className='gradient-card'>
            <Typography variant='headline6'>Jutalék összesítő {selectedYear}-{selectedMonth}</Typography>
            <Stack justifyContent='space-between' direction='row'>
                <Stack spacing={1}>
                    <Typography variant='overline'>Jutalék alap</Typography>
                    <Typography variant='subtitle2'>{thousandSeparator(bonusBase1)} Ft</Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='overline'>KEF -1.8%</Typography>
                    <Typography variant='subtitle2'>-{thousandSeparator(totalKEFFee?.total || 0)} Ft</Typography>
                </Stack>
                {/*<Stack spacing={1}>*/}
                {/*    <Typography variant='overline'>Sales -5%</Typography>*/}
                {/*    <Typography variant='subtitle2'>-{thousandSeparator(Math.round(fivePercent || 0))} Ft</Typography>*/}
                {/*</Stack>*/}
                <Stack spacing={1}>
                    <Typography variant='overline'>Jutalék</Typography>
                    <Typography variant='subtitle2'>{user?.bonus_percent} %</Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='overline'>Bruttó Jutalék</Typography>
                    <Typography variant='subtitle2'>{thousandSeparator(gross)} Ft</Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='overline'>Jutalék szorzó</Typography>
                    <Typography variant='subtitle2'>{user?.bonus_multiplier}</Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='overline'>Nettó jutalék</Typography>
                    <Typography variant='subtitle2'>{thousandSeparator(net)} Ft</Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='overline'>Módosított Jutalék</Typography>
                    <Typography color={theme.palette.primary[500]}
                                variant='subtitle2'>{thousandSeparator(afterModifiers)} Ft</Typography>
                </Stack>
            </Stack>
            <Stack spacing={1}>
                <Typography variant='overline'>Megjegyzés</Typography>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Typography variant='body1'>{comment?.comment ? comment?.comment : '-'}</Typography>
                </Stack>
            </Stack>
        </Stack>

        {bonusModifiers.length > 0 && <Stack className='white-card' marginTop={2}>
            <Typography variant='headline6'>Jutalék módosítók</Typography>
            <Stack spacing={1} marginTop={2}>
                <Stack direction='row' sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant='overline'>Elnevezés</Typography>
                    <Typography variant='overline'>Megjegyzés</Typography>
                    <Typography variant='overline'>Összeg</Typography>
                    <Typography variant='overline'>Kezelés</Typography>
                </Stack>
                {bonusModifiers?.map(m => <Stack direction='row'
                                                 sx={{justifyContent: 'space-between', alignItems: 'center'}}
                                                 key={m?.id}>
                    <Typography variant='caption'>{m?.name}</Typography>
                    <Typography variant='caption'>{m?.comment}</Typography>
                    <Typography variant='caption'
                                color={m?.amount > 0 ? 'green' : 'red'}>{thousandSeparator(m?.amount)} Ft</Typography>
                </Stack>)}
            </Stack>
        </Stack>}

        {/*<Box sx={{*/}
        {/*    width: '100%',*/}
        {/*    display: 'flex',*/}
        {/*    justifyContent: 'flex-end',*/}
        {/*    marginBottom: 2,*/}
        {/*    gap: 3,*/}
        {/*    marginTop: 3*/}
        {/*}}>*/}
        {/*    <FormControl>*/}
        {/*        <Select*/}
        {/*            id="year-select"*/}
        {/*            value={selectedYear}*/}
        {/*            onChange={e => {*/}
        {/*                const selected = e.target.value*/}
        {/*                setSelectedYear(selected)*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            {getYears().map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}*/}
        {/*        </Select>*/}
        {/*    </FormControl>*/}
        {/*    <FormControl>*/}
        {/*        <Select*/}
        {/*            id="month-select"*/}
        {/*            value={selectedMonth}*/}
        {/*            onChange={e => {*/}
        {/*                const selected = e.target.value*/}
        {/*                setSelectedMonth(selected)*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <MenuItem value={1}>Jan</MenuItem>*/}
        {/*            <MenuItem value={2}>Feb</MenuItem>*/}
        {/*            <MenuItem value={3}>Mar</MenuItem>*/}
        {/*            <MenuItem value={4}>Apr</MenuItem>*/}
        {/*            <MenuItem value={5}>Maj</MenuItem>*/}
        {/*            <MenuItem value={6}>Jun</MenuItem>*/}
        {/*            <MenuItem value={7}>Jul</MenuItem>*/}
        {/*            <MenuItem value={8}>Aug</MenuItem>*/}
        {/*            <MenuItem value={9}>Szep</MenuItem>*/}
        {/*            <MenuItem value={10}>Okt</MenuItem>*/}
        {/*            <MenuItem value={11}>Nov</MenuItem>*/}
        {/*            <MenuItem value={12}>Dec</MenuItem>*/}
        {/*        </Select>*/}
        {/*    </FormControl>*/}
        {/*    <FormControl>*/}
        {/*        <Select*/}
        {/*            id="group-select"*/}
        {/*            value={selectedGroup}*/}
        {/*            onChange={e => {*/}
        {/*                const selected = e.target.value*/}
        {/*                setSelectedGroup(selected)*/}
        {/*                localStorage.setItem('group-select', selected)*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <MenuItem value={-1}>Összes csoport</MenuItem>*/}
        {/*            {groups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}*/}
        {/*        </Select>*/}
        {/*    </FormControl>*/}
        {/*</Box>*/}
        <DinoDataGrid rows={data} columns={columns} name='users-detail'/>


        <Loading isLoading={loading}/>
    </AuthDrawer>
}

export default SavedCommissionDetail